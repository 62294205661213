<template>
    <div class="min-h-[500px]">
        <div class="py-2 h-[50px] w-full border-gray-100">
            <div class="flex px-2">
                <el-input
                    v-model="searchName"
                    class="flex-1 max-w-full w-full"
                    clearable
                    placeholder="搜尋名稱"
                    @blur="filterSearch"
                    @keyup.enter.native="filterSearch"
                    @clear="changeTab(currentTab)"></el-input>
            </div>
        </div>
        <div class="flex justify-center border-b border-gray-100">
            <ul class="list-none flex-1 flex justify-center">
                <li
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="flex-grow text-center text-sm py-2 md:text-base max-w-[250px] cursor-pointer"
                    :class="currentTab === tab.key ? 'border-b-2 border-orange-600' : ''"
                    @click="changeTab(tab.key)">
                    <div class="flex justify-center">
                        {{ tab.name }}
                        <div v-if="unReadMessageCountByMember > 0 && tab.key === 'members'" class="w-[10px] h-[10px] bg-red-500 rounded-full"></div>
                        <div
                            v-if="unReadMessageCountByProvider > 0 && tab.key === 'providers'"
                            class="w-[10px] h-[10px] bg-red-500 rounded-full"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div>
            <ul id="chatUsers" ref="chatUsers" class="overflow-y-auto max-h-[500px] bg-white">
                <li
                    v-for="(item, index) in chatUsers"
                    :id="item.banana_id"
                    :key="index"
                    class="cursor-pointer flex items-center px-5 py-2.5"
                    :class="item.banana_id === currentReceiveUserId ? 'bg-yellow-100' : 'bg-white'"
                    @click="changeRoom(item.banana_id, item.role)">
                    <Avatar :backgroundImg="item.avatar" />
                    <div class="ml-3 flex-1">
                        <!-- 只有待約會才會顯示時間 (顯示時間為 開始約會的時間倒數) -->
                        <div class="flex">
                            <h5 class="flex-1 font-medium">
                                {{ item.name
                                }}<span v-if="!item.isBot" class="bg-red-600 px-3 py-1 rounded-full text-white ml-2 text-xs font-extralight"
                                    >真人</span
                                >
                            </h5>
                            <span class="text-xs pt-1 font-light text-gray-300 OpenSansFont">{{ item.lastMsgAt | formatMonthAndDayTime }}</span>
                        </div>
                        <div class="flex mt-1 items-center">
                            <!-- 最後一筆聊天室內容 -->
                            <p class="flex-1 text-gray-300 text-xs">
                                {{ $subString(item.message, 30) }}
                            </p>
                            <!-- 未讀訊息數量 -->
                            <span v-if="item.unReadMessageCount > 0" class="bg-red-600 rounded-full w-5 h-5 text-xs text-white text-center pt-0.5">{{
                                item.unReadMessageCount > 1000 ? 999 : item.unReadMessageCount
                            }}</span>
                        </div>
                    </div>
                </li>
                <li v-if="loading" class="min-h-[50px] w-full text-center">
                    <img class="w-10 h-10 mx-auto block animate-spin" src="/img/loading.svg" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
// 導入大頭照組件
import Avatar from "@/components/Avatar";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseGetChatRoomUserData: 取得 firebase 聊天對象資料
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 * firebaseUserDataUpdate: 更新 users 表未讀訊息數量 以及 updatedAt 時間
 */
import { firebaseGetChatRoomUserData, firebaseSetReadedTime } from "@/service/firebaseChatSet";
// 導入旋轉動畫組件
export default {
    components: {
        Avatar,
    },
    props: {
        // 客服聊天室id
        serviceChatId: {
            type: String,
            default: null,
        },
        // 當前聊天對象資料
        currentChatUserData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 當前聊天對象id
        currentReceiveUserId: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("chatStore", ["unReadMessageCountByMember", "unReadMessageCountByProvider", "lastKey", "chatTab", "chatUsers"]),
        ...mapGetters("chatStore", ["realTimeChatUsers", "realtimeUnReadMessageCount"]),
    },
    data() {
        return {
            // 聊天對象分類
            tabs: [
                { name: "會員", key: "members" },
                { name: "服務商", key: "providers" },
            ],
            // 當前tab (預設值為會員)
            currentTab: "members",
            // 搜尋聊天對象
            searchName: "",
            // 判斷目前是往下滾還是網上滾
            isScrollDown: true,
            loading: false,
        };
    },
    methods: {
        ...mapMutations("chatStore", ["setAssignChatUser", "setResetChatUsers", "setLastKey", "setChatUsersByIndex"]),
        ...mapActions("chatStore", [
            "getChatUsers",
            "getChatUsersPagination",
            "setCurrentReceiveUserMessageReaded",
            "searchChatUsers",
            "checkChatUsersSortChange",
        ]),
        // 搜尋條件
        async filterSearch() {
            // 判斷是否有值 有值就帶入搜尋條件
            if (this.$isEmpty(this.searchName)) {
                this.changeTab(this.currentTab);
                return;
            }
            this.searchChatUsers({
                isGetProviders: false,
                userId: this.serviceChatId,
                name: this.searchName,
                firebaseQuery: this.currentTab,
            });
        },
        /**
         * 搜尋過濾條件 模糊比對方式
         * @param { type Array(陣列) } list 過濾名單
         * @param { type String(字串) } keyword 過濾關鍵字
         */
        fuzzyQuery(list, keyword) {
            var arr = [];
            list.forEach((item) => {
                if (item.name.indexOf(keyword) >= 0) {
                    arr.push(item);
                }
            });
            return arr;
        },
        /**
         * 更換tab
         * @param { type String(字串) } tab 更換的 tab key
         */
        async changeTab(tab) {
            // 當更換 tab 時 自動回滾到最上方
            this.$nextTick(() => {
                const element = document.getElementById("chatUsers");
                element.scrollTo({ top: 0 });
            });
            // 每次更換標籤時 須清空預設定位分頁的 key
            this.setLastKey({ isGetProviders: false, lastKey: null });
            // 清空聊天對象資料
            this.setResetChatUsers();
            // 判斷聊天對象大於0時才作動
            if (this.chatUsers.length > 0) {
                firebaseConnectRef("chat_rooms")
                    .child(this.serviceChatId)
                    .child("users")
                    .orderByChild("lastMsgAt") // 找尋 userData 底下的 role key 判斷身份大於 0 者為服務商
                    .off();
            }
            this.loading = true;
            // 判斷當前 tab 不等於選中 tab 時 清空搜尋暱稱值
            if (tab !== this.currentTab) {
                this.searchName = null;
            }
            this.currentTab = tab;
            // 點擊另外一個 tab 時更換網址參數
            if (this.$route.params.id !== undefined) {
                this.$router
                    .push({
                        name: "chats",
                        params: {
                            tab: this.currentTab,
                            id: this.$route.params.id,
                        },
                    })
                    .catch((err) => {
                        err;
                    });
            } else {
                this.$router.push({ name: "chats", params: { tab: this.currentTab } }).catch((err) => {
                    err;
                });
            }
            // 取的聊天對象資料
            await this.getUserList(tab);
            // 判斷沒有聊天對象不往下執行
            if (this.$isEmpty(this.realTimeChatUsers)) {
                return;
            }
            // 設定服務商 與 會員 聊天對象
            // this.setChatTabUsers(tab);
            // 判斷搜尋暱稱是否有值 有值的話需過濾出搜尋暱稱對象
            if (!this.$isEmpty(this.searchName)) {
                this.filterSearch();
            }
            // 設定當前聊天對象資料
            // this.setCurrentChatUserData();
            // 延遲一秒在關閉旋轉動畫 以防閃爍太嚴重
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
        /**
         * 更換聊天室
         * @param { type String(字串) } userId 會員id
         * @param { type Number(數字) } role 判斷會員身分或服務商 ( role > 0 為服務商身份)
         * @param { type Boolean(布林) } isChangeLastKey 判斷是否重新定義 firebase 分頁 lastKey (因為重新整理頁面時不可觸發 因此多傳個值判斷)
         */
        async changeRoom(userId, role, isChangeLastKey = true) {
            // 監聽聊天對象是否有資料更新
            this.monitorUser(userId);
            // 設定聊天對象id
            this.$emit("update:currentReceiveUserId", userId);
            this.$nextTick(async () => {
                // 取消原本監聽 (為了防止多重聊天室監聽情況發生)
                this.disconnect();
                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空聊天對象資料
                this.$emit("update:currentChatUserData", {});
                // 清空輸入匡值
                this.$emit("onClearnMessage");
                // 判斷目前選擇聊天室 非當前聊天室時 才做導頁處理
                if (this.$route.params.id !== userId) {
                    // 導向對應的 聊天室
                    this.$router.push({
                        name: "chats",
                        params: {
                            tab: role > 0 ? "providers" : "members",
                            id: userId,
                        },
                    });
                }
                // 重新取得對應聊天室資料
                this.$emit("onGetMessageList");
                // 更新當前聊天對象資料
                await this.setCurrentChatUserData(isChangeLastKey);
                // 判斷有搜尋聊天對象時 不往下執行
                if (!this.$isEmpty(this.searchName)) {
                    return;
                }
                this.$nextTick(async () => {
                    // 判斷當高度小於 500 時需動態更新視窗高度 因為預設高度最高限制 500 不然會沒有捲軸出現 無法觸發分頁機制
                    if (this.$refs.chatUsers.scrollHeight < 500) {
                        await this.scrollDownGetPagination();
                    }
                });
            });
        },
        /**
         * 當前聊天對象資料
         * @param { type Boolean(布林) } isChangeLastKey 判斷是否重新定義 firebase 分頁 lastKey (因為重新整理頁面時不可觸發 因此多傳個值判斷)
         */
        async setCurrentChatUserData(isChangeLastKey = true) {
            try {
                const userData = await firebaseGetChatRoomUserData(this.currentReceiveUserId);
                // 當使用者資料沒有時 不往下執行
                if (userData === null) {
                    return;
                }
                // 判斷lastMsgAt值 不可為 undefined 或 小於 0 或者是 NaN
                if (userData.lastMsgAt !== null && isChangeLastKey) {
                    this.setLastKey({ isGetProviders: false, lastKey: -userData.lastMsgAt });
                }
                // 判斷當前聊天對象未讀訊息是否大於 0 如果大於 0要清空
                if (userData.unReadMessageCount > 0) {
                    // 更新當前聊天對象未讀訊息為 0
                    userData.unReadMessageCount = 0;
                    // 更新 firebase 聊天對象未讀訊息為 0
                    this.setCurrentReceiveUserMessageReaded({
                        allUsers: this.realTimeChatUsers,
                        currentReceiveUserId: this.currentReceiveUserId,
                        serviceChatId: this.serviceChatId,
                    });
                    // 更新聊天對象已讀訊息時間
                    await firebaseSetReadedTime(this.currentReceiveUserId, this.serviceChatId);
                }
                // 用來更新切換機器人或真人客服時 找尋到對應的 index 去做更換聊天對象的顯示
                const index = this.realTimeChatUsers.findIndex((item) => {
                    return item.banana_id === userData.banana_id;
                });
                // 判斷有找到值時 才執行 去做更換聊天對象的顯示
                if (index !== -1) {
                    this.setChatUsersByIndex({ isGetProviders: false, index, data: userData });
                }
                // 更新當前聊天對象資料
                this.$emit("update:currentChatUserData", userData);
            } catch (err) {
                return {};
            }
        },
        // 取消 firebase 監聽
        disconnect() {
            // 判斷是否有 user 資料 因為登出時會清出空
            if (this.serviceChatId !== undefined) {
                // 取消聊天對象未讀訊息數量監聽
                firebaseConnectRef("chat_rooms").child(this.currentReceiveUserId).child("users").child(this.serviceChatId).off();
                // 取消聊天室監聽
                firebaseConnectRef("chats").child("users").child(this.currentReceiveUserId).child("messages").off();
            }
        },
        /**
         * 監聽聊天對象是否有資料更新
         */
        monitorUser(receiveUserId) {
            firebaseConnectRef("chat_rooms")
                .child(this.serviceChatId)
                .child("users")
                .child(receiveUserId)
                .on("child_changed", (snapshot) => {
                    // 判斷非登入使用者
                    if (snapshot.key === "readedAt") {
                        // 取得聊天對象更改資料者使用者
                        const receiveUserIndex = this.chatUsers.findIndex((user) => user.banana_id === receiveUserId);
                        // 判斷是否有取得
                        if (receiveUserIndex !== -1) {
                            this.setAssignChatUser({ isGetProviders: false, index: receiveUserIndex, readedAt: snapshot.val() });
                            // 將使用者資料更新為最新結果
                            this.setCurrentChatUserData();
                        }
                    }
                });
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatUsers;
            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart = dom.scrollTop + dom.clientHeight >= dom.scrollHeight;
            // 觸發滾動更新分頁
            if (getDataStart && this.$isEmpty(this.searchName)) {
                this.scrollDownGetPagination();
                return;
            }
            // //  當滾動高度 小於或等於 新人榜高度時做觸發
            if (dom.scrollTop <= 0) {
                this.scrollUpGetPrevPagination();
            }
        },
        /**
         * 取得聊天對象列表
         * @param { type String(字串) } query 區分找尋會員或服務商聊天對象參數 (ex: "members" 會員, "providers" 服務商)
         * @example 服務商 [1,2] 會員 [0,0]
         */
        async getUserList(query) {
            // this.$nextTick(() => {
            //     this.$refs.chatUsers.style.height = "0";
            // });
            await this.getChatUsers({
                isGetProviders: false,
                userId: this.serviceChatId,
                currentReceiveUserId: this.currentReceiveUserId,
                firebaseQuery: query,
                lastKey: this.lastKey,
            });
            this.$nextTick(async () => {
                // 當捲軸高度小於500  且聊天對象大於0時 觸發
                if (this.$refs.chatUsers.scrollHeight < 500 && this.realTimeChatUsers.length > 0) {
                    // 當資料小於目前 dom 高度時 觸發分頁機制
                    await this.scrollDownGetPagination();
                } else {
                    // this.$refs.chatUsers.style.height = `auto`;
                }
            });
            this.checkChatUsersSortChange({
                userId: this.serviceChatId,
                allUsers: this.realTimeChatUsers,
            });
        },
        // 顯示載入動畫時間
        loadingTime() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, 500);
            });
        },

        /**
         * 分頁機制
         */
        async scrollDownGetPagination() {
            this.loading = true;
            // 判斷目前是往下滾狀態
            this.isScrollDown = true;
            if (this.lastKey === "end") {
                console.log("is end");
                this.loading = false;
                return;
            }
            await this.loadingTime();
            await this.getChatUsersPagination({
                isGetProviders: false,
                userId: this.serviceChatId,
                currentReceiveUserId: this.currentReceiveUserId,
                firebaseQuery: this.currentTab,
                lastKey: this.lastKey,
                allUsers: this.realTimeChatUsers,
            });
            this.loading = false;
            this.$nextTick(async () => {
                // 判斷當高度小於 500 時觸發
                if (this.$refs.chatUsers.scrollHeight < 500) {
                    // 當目前卷軸高度小於聊天對象的視窗高度(500)時 觸發分頁機制 遞迴自己 (因為分頁中取得比數會因為過濾條件完後 變成筆數過少問題 因此遞迴自己)
                    await this.scrollDownGetPagination();
                } else {
                    // 如果超過 500 高度時 將高度設為 auto
                    // this.$refs.chatUsers.style.height = "auto";
                }
                const dom = this.$refs.chatUsers;
                dom.scrollTo({ top: dom.scrollTop });
            });
        },
        scrollUpGetPrevPagination() {
            // this.setLastKey({ isGetProviders: false, lastKey: null });
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                this.$refs.chatUsers.addEventListener("scroll", this.getMoreData);
            });
        },
        /**
         * 設定分頁資料
         * @param { type Array(陣列) } data 分頁資料
         */
        setDatas() {
            this.windowSrcoll();
        },
    },
    watch: {
        // 判斷網址是否有改變 代表使用者有切換聊天室 因此需重新設定以下資料
        "$route.params.id": function (val) {
            // 判斷 tab 有值時 更改預設 tab
            if (this.$route.params.tab !== undefined) {
                this.currentTab = this.$route.params.tab;
            }
            // 判斷是否有選擇聊天對象 如果有選擇 會取的聊天對象 id (回聊天室首頁情況下會沒有聊天對象 id)
            if (this.$route.params.id !== undefined) {
                // 當聊天對象改變時 設定聊天對象 id
                this.$emit("update:currentReceiveUserId", val);
                // 等待 所有畫面以渲染完後 在進行監聽 否則會有取得聊天對象 id 為 null 情形發生
                this.$nextTick(() => {
                    this.changeRoom(val);
                });
            } else {
                // 清空聊天室訊息
                this.$emit("onClearChatRoomMessages");
                // 如果未選中聊天對象時 須清空聊天對象資料
                this.$emit("update:currentChatUserData", {});
                // 如果未選中聊天對象時 須清空聊天對象id
                this.$emit("update:currentReceiveUserId", null);
            }
        },
        chatTab(val) {
            // this.currentTab = val;
            this.changeTab(val);
        },
    },
    async created() {
        // 先清空預設定位分頁的 key
        this.setLastKey({ isGetProviders: false, lastKey: null });
        // 判斷 tab 有值時 更改預設 tab
        if (this.$route.params.tab !== undefined) {
            this.currentTab = this.$route.params.tab;
        }
        this.changeTab(this.currentTab);
        // 判斷是否有選擇聊天對象 如果有選擇 會取的聊天對象 id (回聊天室首頁情況下會沒有聊天對象 id)
        if (this.$route.params.id !== undefined) {
            // 啟用監聽聊天對象是否有更新值
            this.monitorUser(this.$route.params.id);
            this.$emit("update:currentReceiveUserId", this.$route.params.id);
            // 等待 所有畫面以渲染完後 在進行監聽 否則會有取得聊天對象 id 為 null 情形發生
            this.$nextTick(() => {
                this.changeRoom(this.$route.params.id, this.currentTab, false);
            });
        } else {
            // 如果未選中聊天對象時 須清空聊天對象資料
            this.$emit("update:currentChatUserData", {});
            // 如果未選中聊天對象時 須清空聊天對象id
            this.$emit("update:currentReceiveUserId", null);
        }
        this.setDatas();
    },
};
</script>
