<template>
    <div class="grid grid-cols-12 gap-4 px-5">
        <div v-if="$Debug" class="col-span-12">
            <el-button @click="testDatas">測試假資料</el-button>
        </div>
        <div v-if="$Debug" class="col-span-12">
            <el-button type="danger" @click="deleteUsers">刪除假資料</el-button>
        </div>
        <div v-if="$Debug" class="col-span-12">
            <el-button type="danger" @click="deleteServiceRoomUser">刪除客服聊天對象</el-button>
        </div>
        <div v-if="$Debug" class="col-span-12">
            <el-button type="danger" @click="deleteChatRooms">刪除全部聊天室</el-button>
        </div>
        <div class="col-span-4 bg-white p-3 rounded-lg">
            <Users
                ref="users"
                :serviceChatId="serviceChatId"
                :message.sync="message"
                :isReadHistory.sync="isReadHistory"
                :currentChatUserData.sync="currentChatUserData"
                :currentReceiveUserId.sync="currentReceiveUserId"
                @onGetMessageList="getMessageList"
                @onClearnMessage="clearnMessage"
                @onClearChatRoomMessages="clearChatRoomMessages" />
        </div>
        <div class="col-span-8 bg-gray-50 rounded-lg shadow-lg">
            <Messages
                v-if="currentReceiveUserId !== null"
                ref="messages"
                :serviceChatId="serviceChatId"
                :currentChatUserData="currentChatUserData"
                :currentReceiveUserId="currentReceiveUserId"
                :isReadHistory.sync="isReadHistory"
                @onResetCurrentUserData="resetCurrentUserData" />
            <SendMessage
                v-if="$route.params.id !== undefined && currentChatUserData.avatar !== ''"
                ref="sendMessage"
                v-permission="['update']"
                :serviceChatId="serviceChatId"
                :currentChatUserData="currentChatUserData"
                :currentReceiveUserId="currentReceiveUserId"
                :isReadHistory.sync="isReadHistory" />
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
// 導入 聊天對象組件
import Users from "./components/Users.vue";
// 導入 聊天對象組件
import Messages from "./components/Messages.vue";
// 導入 發送聊天訊息組件
import SendMessage from "./components/SendMessage.vue";
import { firebaseTestChatRoomsData, firebaseDeleteChatUsers, firebaseDeleteChatRooms } from "@/service/firebaseChatSet";
export default {
    name: "Chats",
    components: {
        Users,
        Messages,
        SendMessage,
    },
    data() {
        return {
            // 當前聊天對象id
            currentReceiveUserId: null,
            // 當前聊天對象使用者資料
            currentChatUserData: {},
            // 聊天室客服 id
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            // 判斷是否閱讀歷史訊息 (主要用意是判斷是否將聊天視窗滾到底)
            isReadHistory: false,
            // 發送訊息輸入匡值
            message: "",
        };
    },
    methods: {
        ...mapMutations("chatStore", ["setResetChatUsers"]),
        // 測試產生firebase 聊天對象用假資料
        async testDatas() {
            // 判斷是 debug 模式下才可執行
            if (!this.$Debug) {
                return;
            }
            for (let i = 0; i < 100; i++) {
                await firebaseTestChatRoomsData(this.serviceChatId, i);
            }
        },
        // 刪除假資料聊天室
        async deleteUsers() {
            // 判斷是 debug 模式下才可執行
            if (!this.$Debug) {
                return;
            }
            for (let i = 0; i < 100; i++) {
                await firebaseDeleteChatUsers(i);
            }
        },
        // 刪除客服聊天對象
        async deleteServiceRoomUser() {
            // 判斷是 debug 模式下才可執行
            if (!this.$Debug) {
                return;
            }
            await firebaseDeleteChatUsers(this.serviceChatId);
        },
        // 刪除整個聊天室
        async deleteChatRooms() {
            // 判斷是 debug 模式下才可執行
            if (!this.$Debug) {
                return;
            }
            await firebaseDeleteChatRooms();
        },
        // 重新取得聊天室訊息
        getMessageList() {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.getMessageList();
                }
            });
        },
        // 清空輸入匡訊息
        clearnMessage() {
            this.$nextTick(() => {
                if (this.$refs.sendMessage !== undefined) {
                    this.$refs.sendMessage.clearnMessage();
                }
            });
        },
        // 清空聊天室訊息
        clearChatRoomMessages() {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.clearChatRoomMessages();
                }
            });
        },
        // 取消 firebase 監聽
        disconnect() {
            // 判斷是否有 user 資料 因為登出時會清出空
            if (this.serviceChatId !== undefined) {
                // 取消聊天對象未讀訊息數量監聽
                firebaseConnectRef("chat_rooms").child(this.serviceChatId).child("users").off();
                // 取消聊天室監聽
                firebaseConnectRef("chats").off();
                // 判斷是否有顯示 聊天訊息組件
                if (this.$refs.messages !== undefined) {
                    // 取消監聽聊天對象
                    this.$refs.messages.disconnectChatUserData(this.serviceChatId, this.currentReceiveUserId);
                }
            }
        },
        /**
         * 返回聊天對象列表頁
         */
        resetCurrentUserData() {
            this.$nextTick(() => {
                if (this.$refs.users !== undefined) {
                    this.$refs.users.setCurrentChatUserData();
                }
            });
        },
    },
    watch: {
        "$route.params.id": function (val) {
            // 判斷是否有顯示 聊天對象的 component
            if (this.$refs.messages !== undefined) {
                // 取消監聽聊天對象 (因為不取消會監聽太多聊天對象 所以每次監聽新的聊天對象時 先取消舊的)
                this.$refs.messages.disconnectChatUserData(this.serviceChatId, val);
                // 監聽聊天對象
                this.$refs.messages.connectChatUserData(this.serviceChatId, val);
            }
        },
    },
    beforeDestroy() {
        this.setResetChatUsers();
        // 取消監聽
        this.disconnect();
    },
};
</script>
